@use '@/styles/utils/mixins.scss' as *;
.headingElement {
  position: relative;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'KiaSignature';
    font-weight: 600;
    font-style: normal;
    &.titleLg {
      font-size: 48px;
      line-height: 60px;
      color: $kiaPolarWhite;
      @include tab() {
        font-size: 36px;
        line-height: 43.2px;
      }
      // @include mob() {
      //   font-size: 24px;
      //   line-height: 28.8px;
      // }
    }
    &.titleXs {
      font-size: 16px;
      line-height: 19.2px;
    }
  }
  .titleText {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 28px;
  }
}
.discoverySectionWrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  max-width: 100%;

  @include tab() {
    flex-direction: column;
    max-height: none;
  }

  @include mob() {
    max-height: none;
  }

  .leftSection {
    position: relative;
    width: 100%;
    @include tab() {
      width: 100%;
    }
    .imageWrapper {
      position: relative;
      z-index: 0;
      height: 100%;
      img {
        object-fit: cover;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        @include mob() {
          top: 1px;
        }
      }
      img {
        position: static !important;
        max-height: 486px !important;

        @include mob() {
          max-height: 300px !important;
        }
      }
    }
  }
  .rightSection {
    width: 100%;
    padding: 56px;
    color: $kiaPolarWhite;
    background-color: $kiaMidnightBlack;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 1;
    @media screen and (min-width: 992px) {
      max-width: 528px;
      margin-left: auto;
    }
    @include tab() {
      padding: 80px 30px;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
      }
    }
    @include mob() {
      padding: 50px 24px;
    }
  }
}
