@use '@/styles/utils/mixins.scss' as *;

.resultsPagination {
  display: flex;
  justify-content: center;

  li {
    margin: 8px !important;
  }

  a.link {
    display: block !important;
    width: 100%;
    height: 100%;
    color: $kiaMidnightBlack;

    &:hover {
      text-decoration: underline;
    }
  }

  li:not(.ant-pagination-item-active) a.link {
    color: $kiaMidnightBlack;
  }

  li.ant-pagination-item-active {
    border-color: #00b894;
    border-radius: 6px;

    a.link {
      font-weight: bold;
      text-decoration: underline;
      color: $kiaMidnightBlack;
    }
  }
}
