@use '@/styles/utils/vars.scss' as *;

.paymentMethodWrapper {
  margin-bottom: 54px;

  &:last-child {
    margin-bottom: 0;
  }

  .sectionWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
  }
}

.flex {
  display: flex;
  align-items: center;
}

.priceFormatText {
  font-size: 12px;
  line-height: 18px;
  color: $kiaMidnightBlack;
}

.sliderRange {
  :global {
    .ant-slider-mark {
      top: 20px !important;
    }
  }
}

@media screen and (max-width: $maxTablet) {
  .paymentMethodWrapper {
    margin-bottom: 64px;

    &:last-child {
      margin-bottom: 64px;
    }
  }

  .sliderRange {
    :global {
      .ant-slider-mark {
        top: 28px !important;
      }
    }
  }
}
