@use '@/styles/utils/vars.scss' as *;

.wrapper {
  margin: 24px 0 0;
  color: $kiaMidnightBlack;

  @media screen and (max-width: $maxTablet) {
    padding: 0 24px;

    .item {
      font-weight: 600;
    }
  }

  .item {
    font-weight: 400;
    margin-right: 10px;
    text-transform: capitalize;
    font-size: 16px;
    line-height: 1;

    &:hover {
      cursor: pointer;
    }
  }

  .homeItem {
    @media screen and (max-width: $maxTablet) {
      display: none !important;
    }
  }

  .itemActive {
    color: $kiaMidnightBlack;
    font-weight: 400;
  }

  .itemSeparator {
    margin-right: 10px;
  }

  .homeIcon {
    width: 12px;
    height: 12px;
    background: url('/home.png') no-repeat;
  }
}
