@use '@/styles/utils/vars.scss' as *;

.mobileResults {
  display: none;
}

@media screen and (max-width: $maxTablet) {
  .mobileResults {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    color: $kiaMidnightBlack;
    padding-right: 22px;
  }
}
