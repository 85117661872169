
@use '@/styles/utils/vars.scss' as *;

.priceFinanceWrapper {
  box-sizing: border-box;
  width: 826px;
  padding: 18px 26px;

  .priceFinanceTabsWrapper {
    width: 321px;
    height: 36px;
    display: flex;
    align-items: center;
    border-radius: 100px;
    border: solid 1px $border;
    background-color: $background;
    margin-bottom: 20px;
  }

  .priceFinanceTab {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
    color: $navigationSeparator;
  }

  .priceFinanceTabActive {
    color: $kiaPolarWhite;
    border-radius: 100px;
    background-color: $kiaMidnightBlack;
  }
}

@media screen and (max-width: $maxTablet) {
  .priceFinanceWrapper {
    width: 100%;
    padding: 12px 22px;

    .priceFinanceTabsWrapper {
      width: 316px;
      justify-content: center;
      margin: 0 auto 24px;
    }
  }
}
