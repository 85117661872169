@use '@/styles/utils/mixins.scss' as *;

.SearchInput {
  width: 100%;
  height: 100%;

  padding: 0 16px;
  border: 1px solid black;
  outline: none;
}

.SearchInput:focus {
  border-radius: 0;
  border-width: 2px;
  border-color: black;
  padding: 0 15px;
}

.Container {
  width: 100%;
  display: flex;
  align-items: center;
}
