@use '@/styles/utils/mixins.scss' as *;

.headerSectionWrapper {
  .headingElement {
    position: relative;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;

      &.titleXl {
        font-size: 60px;
        line-height: 1;
        @include tab() {
          font-size: 48px;
          line-height: 1;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 1;
        @include tab() {
          font-size: 36px;
          line-height: 1;
        }
      }
      &.titleMd {
        font-size: 48px;
        line-height: 1;
        @include tab() {
          font-size: 24px;
          line-height: 1;
        }
      }
      &.titleXs {
        font-size: 16px;
        line-height: 1;
      }
    }
    .titleText {
      font-size: 16px;
      line-height: 1;
      font-weight: 600;
    }
  }
  .ctaBtn {
    padding: 13px 24px;
    text-transform: capitalize;
    background-color: $kiaForestGreen;
  }
  .dealershipLocation {
    font-size: 16px;
    line-height: 1.83;
    font-weight: bold;
    color: $kiaMidnightBlack;
    width: fit-content;
    display: flex;
    gap: 10px;
    align-items: baseline;
  }
  .breadcrumb {
    @include mob-tab() {
      display: none;
    }

    &Link {
      display: flex;
      align-items: center;
      gap: 8px;
      color: $kiaMidnightBlack;
      font-size: 16px;
      line-height: 1.25;

      svg {
        margin-top: -2px;
      }
    }
  }
  .bakcBtnContainer {
    display: none;
    @include mob-tab() {
      display: block;
    }
  }
  .backBtn {
    display: flex;
    background: transparent !important;
    width: max-content;
    flex: 0 0 auto;
    padding: 0;
    height: auto !important;
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    align-items: center;
    border-radius: 0 !important;
    color: $kiaMidnightBlack;
    -webkit-transition: all 450ms ease-in-out;
    -moz-transition: all 450ms ease-in-out;
    -o-transition: all 450ms ease-in-out;
    -ms-transition: all 450ms ease-in-out;
    transition: all 450ms ease-in-out;
    margin-bottom: 10px;
    svg {
      padding-bottom: 2px;

      &:focus {
        color: $kiaMidnightBlack;
        outline: 0;
      }
    }
    .backBtnText {
      -webkit-transition: all 450ms ease-in-out;
      -moz-transition: all 450ms ease-in-out;
      -o-transition: all 450ms ease-in-out;
      -ms-transition: all 450ms ease-in-out;
      transition: all 450ms ease-in-out;
      padding-bottom: 2px;
      position: relative;
      &::before {
        content: '';
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0px;
        height: 1px;
        width: 0;
        background-color: $kiaMidnightBlack;
        transition: width 0.3s;
      }

      &:focus {
        color: $kiaMidnightBlack;
        outline: 0;
      }
    }

    &:hover,
    &:active,
    &:focus {
      color: $kiaMidnightBlack;
      .backBtnText {
        color: $kiaMidnightBlack;
        &::before {
          width: 100%;
          transition: width 0.3s;
        }
      }
    }
    &.active {
      color: $kiaMidnightBlack;
      .backBtnText {
        color: $kiaMidnightBlack;
        &::before {
          width: 100%;
          transition: width 0.3s;
        }
      }
    }
  }
}
