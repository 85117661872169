@use '@/styles/utils/vars.scss' as *;

.filterTagListWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 40px;
  width: 100%;
  max-width: fit-content;
  box-sizing: border-box;
  padding: 0 12px 6px 10px;
  background-color: $kiaPolarWhite;
  border: 1px solid $border;
  border-left: none;

  .filterTagListClearAll {
    width: max-content;
    color: $kiaLiveRed;
    text-decoration: underline;
    font-size: 12px;
    line-height: 18px;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 8px;
  }
}

.indicator {
  box-sizing: border-box;
  background-color: $kiaMidnightBlack;
  border-radius: 50%;
  height: 25px;
  display: flex;
  align-items: center;
  padding: 0 10px;

  .count {
    font-size: 12px;
    line-height: 13px;
    font-weight: bold;
    color: $kiaPolarWhite;
  }
}

@media screen and (max-width: $maxTablet) {
  .filterTagListWrapper {
    border: none;
    padding: 0;
  }
}
