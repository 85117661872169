@use '@/styles/utils/vars.scss' as *;

.filterIcon {
  position: relative;
  width: 40px;
  height: 40px;
  border: 1px solid $kiaMidnightBlack;
  border-radius: 0px!important;
  background-color: $background!important;
  display: inline-flex !important;

  svg {
    width: 40px;
    height: 40px;
  }
}

.filterBadge {
  top: -16px !important;
  right: -8px !important;
  font-size: 12px;
}
