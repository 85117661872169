@use '@/styles/utils/vars.scss' as *;

.term {
  box-sizing: border-box;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  border: solid 1px $border;
  background-color: $kiaPolarWhite;
  font-size: 12px;
  line-height: 16px;
  color: $kiaMidnightBlack;
  cursor: pointer;
  margin-right: 13px;

  &:last-child {
    margin-right: 0;
  }

  &Selected {
    background-color: $kiaMidnightBlack;
    border-color: $kiaMidnightBlack;
    color: $kiaPolarWhite;
  }
}

@media screen and (max-width: $maxTablet) {
  .term {
    width: 50px;
    height: 46px;
    margin-right: 3px;
  }
}
