@use '@/styles/utils/vars.scss' as *;

.mainSearch {
  display: flex;
  min-height: 40px;
  margin-bottom: 18px;

  :global {
    .ant-select {
      height: 40px;

      .ant-select-selector {
        border-color: $inputBorderColor !important;
        background-color: transparent !important;
      }
    }
  }
}

.mobileTagList {
  width: 100%;
  max-width: fit-content;
}

@media screen and (max-width: $maxTablet) {
  .mainSearch {
    margin-bottom: 18px;
    position: relative;

    :global {
      .ant-select {
        min-width: auto !important;
      }
    }
  }
}

@media screen and (min-width: $minDesktop) {
  .desktopFilters {
    display: none;
  }
}

@media screen and (max-width: $minDesktop) {
  .mobileTagList {
    display: none;
  }
}
