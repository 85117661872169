@use '@/styles/utils/mixins.scss' as *;
.safetyReportWrapper {
  .headingElement {
    position: relative;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;

      &.titleXl {
        font-size: 60px;
        line-height: 1;

        @include tab() {
          font-size: 48px;
          line-height: 1;
        }
      }

      &.titleLg {
        font-size: 48px;
        line-height: 1;

        @include tab() {
          font-size: 36px;
          line-height: 1;
        }
      }

      &.titleMd {
        font-size: 20px;
        line-height: 1;
      }

      &.titleXs {
        font-size: 16px;
        line-height: 1;
      }
    }

    .titleText {
      font-size: 16px;
      line-height: 1;
      font-weight: 600;
    }
  }

  .btnTextLink {
    display: flex;
    align-items: center;
    gap: 8px;
    text-decoration: none;
    border-bottom: 0;
    background-color: transparent;
    padding: 0;
    border: 0;
    color: $kiaMidnightBlack;
    font-size: 14px;
    line-height: 1;
    font-weight: 600;

    .linkText {
      border-bottom: 1px solid $kiaMidnightBlack;
      -webkit-transition: all 450ms ease-in-out;
      -moz-transition: all 450ms ease-in-out;
      -o-transition: all 450ms ease-in-out;
      -ms-transition: all 450ms ease-in-out;
      transition: all 450ms ease-in-out;
      &:hover,
      &:active {
        border-bottom: 1px solid transparent;
        color: $kiaMidnightBlack;
      }
      &.active {
        border-bottom: 1px solid transparent;
        color: $kiaMidnightBlack;
      }
    }
    &:hover,
    &:active {
      color: $kiaMidnightBlack;
      background-color: transparent;
    }
    &.active {
      color: $kiaMidnightBlack;
      background-color: transparent;
    }
    &:hover,
    &:focus,
    &:active,
    &:focus-visible {
      text-decoration: none;
      background-color: transparent;
    }
  }

  .safetyReportTable {
    tbody > tr > td {
      border-bottom: 1px solid #f0f0f0;
      transition: background 0.3s;
      padding: 16px 20px;
      font-family: 'KiaSignature';
      font-style: normal;
    }

    tr:first-child > td {
      border-top: 1px solid #f0f0f0;
    }

    tr:nth-of-type(even) > td {
      background: $background;
    }

    tr:hover > td {
      background: $background;
    }
  }

  .specName {
    line-height: 1.14;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .specPassed {
    font-size: 12px;
    color: $kiaForestGreen;
    width: 90px;
  }
  .specPassedMark {
    width: 60px;
  }
  .specPassedMarkSymbole {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $kiaForestGreen;
    border-radius: 50%;
  }
}
