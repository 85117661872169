@use '@/styles/utils/vars.scss' as *;

.paymentTermsWrapper {
  display: flex;
  margin-top: 17px;
}

.sectionNameWrapper {
  height: 30px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: $maxTablet) {
  .paymentTermsWrapper {
    margin-top: 21px;
  }
}
