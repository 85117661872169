@use '@/styles/utils/vars.scss' as *;

.priceFilterPromo {
  position: relative;
  box-sizing: border-box;
  height: 224px;
  margin-top: -56px;
  background-size: cover;
  box-shadow: inset 0 0 0 150px $overlayShadow;
  overflow: hidden;

  .promoImage {
    height: 224px;
    background-color: #777777;

    img {
      object-fit: cover;
      width: 100%;
      height: 224px;
      display: block;
    }
  }

  .promoWrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 224px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 16px 12px 24px;
  }

  .priceFilterPromoArticle {
    max-width: 250px;
    color: $kiaPolarWhite;
    font-size: 12px;
    line-height: 16px;
  }

  .priceFilterPromoArticleTitle {
    font-weight: bold;
    text-transform: uppercase;
  }

  .priceFilterPromoButton {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    height: 38px;
    width: max-content;
    padding: 12px 20px;
    border: 1px solid $kiaPolarWhite;
    font-size: 12px;
    line-height: 18px;
    font-weight: bold;
    color: $kiaPolarWhite;
  }
}

@media screen and (max-width: $maxTablet) {
  .priceFilterPromo {
    margin-top: 82px;
    height: 248px;

    .promoImage {
      height: 248px;

      img {
        height: 248px;
      }
    }

    .promoWrapper {
      height: 248px;
      padding: 18px 24px 18px 24px;
    }
  }
}
