@use '@/styles/utils/vars.scss' as *;

.filterInput {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  flex-grow: 1;
  height: 40px;
  padding: 0 11px;
  border: solid 1px $inputBorderColor;
  background-color: $kiaPolarWhite;
  font-size: 12px;
  line-height: 13px;
  color: $kiaMidnightBlack;
  width: 100%;
}

@media (min-width: 1206px) {
  .filterInput {
    flex-basis: 100px;
    width: auto;
  }
}

.filterInputActive {
  border-color: $kiaMidnightBlack;

  .filterTriangle {
    transform: scaleY(-1);

    path {
      fill: $kiaMidnightBlack;
    }
  }
}

.filterContent {
  font-size: 12px;
  overflow: auto;
}

.bodyFilter {
  position: relative;
  width: min-content;

  @media (max-width: 1206px) {
    left: 204px !important;
  }
}

.full {
  width: auto !important;
}


