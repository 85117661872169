@use '@/styles/utils/vars.scss' as *;

@media screen and (max-width: $maxTablet) {
  .filtersCounterWrapper {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $kiaPolarWhite;
    border: 1px solid $kiaMidnightBlack;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;

    .filtersCounter {
      box-sizing: border-box;
      background-color: $kiaMidnightBlack;
      border-radius: 100px;
      height: 24px;
      padding: 4px 12px;
      display: flex;
      align-items: center;

      .filtersCounterLabel {
        cursor: pointer;
        font-size: 12px;
        line-height: 13px;
        font-weight: bold;
        color: $kiaPolarWhite;
      }
    }

    .filtersCounterHide {
      box-sizing: border-box;
      background-color: $kiaPolarWhite;
      border-radius: 100px;
      height: 24px;
      padding: 4px 24px;
      display: flex;
      align-items: center;
      border: 1px solid $kiaMidnightBlack;

      .filtersCounterHideLabel {
        cursor: pointer;
        font-size: 12px;
        line-height: 13px;
        font-weight: bold;
        color: $kiaMidnightBlack;
      }
    }
  }
}
