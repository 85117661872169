@use '../../../../styles/utils/vars.scss' as *;

.body {
  width: 1206px;
  margin: 0 auto;

  .bodySection {
    margin-bottom: 23px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .bodySectionName {
    font-size: 12px;
    line-height: 14px;
    color: $kiaMidnightBlack;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .bodyContent {
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: $maxTablet) {
  .body {
    min-width: unset;
    max-height: unset;
    width: initial;
    padding: 12px 22px 12px;

    .bodyContent {
      flex-wrap: wrap;
    }
  }
}
