@use '@/styles/utils/vars.scss' as *;

.blockWrapper {
  max-width: 1328px;
  margin: 0 auto;

  @media screen and (max-width: 1300px) {
    width: 90%;
  }

  @media screen and (max-width: $maxTablet) {
    width: 100%;
  }
}
