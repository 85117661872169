@use '@/styles/utils/vars.scss' as *;

.bodyTypeItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-right: 30px;

  &:hover {
    text-shadow: 0.25px 0px 0.1px, -0.25px 0px 0.1px;
  }

  &:last-child {
    margin-right: 0;
  }

  .bodyTypeImage {
    max-height: 40px;
    object-fit: contain;
    margin-bottom: 6px;
  }

  .bodyTypeText {
    font-size: 12px;
    line-height: 14px;
    color: $kiaMidnightBlack;
  }
}

.bodyTypeItemSelected {
  position: relative;

  .bodyTypeText {
    text-shadow: 0.25px 0px 0.1px, -0.25px 0px 0.1px;
    position: relative;

    &::after {
      content: url('../../../../../_icons/check.png');
      position: absolute;
      right: -13px;
    }
  }
}

.bodyTypeItemDisabled {
  pointer-events: none;
  opacity: 0.5;
}

@media screen and (max-width: $maxTablet) {
  .bodyTypeItem {
    margin-bottom: 10px;

    .bodyTypeImage {
      max-height: 30px;
    }
  }
}
