@use '@/styles/utils/mixins.scss' as *;

.heading {
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 10px;
}

.sliderWrapper {
  padding: 15px;
}