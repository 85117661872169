@use '@/styles/utils/vars.scss' as *;

.pageDescription {
  font-size: 18px;
  letter-spacing: -0.36px;
  line-height: 24px;
  max-width: 900px;
  margin: 18px 0;
}

@media screen and (max-width: $maxTablet) {
  .pageDescription {
    padding: 0 22px;
    font-size: 13px;
    font-weight: normal;
    line-height: 1.31;
    letter-spacing: -0.13px;
    max-width: unset;
    margin: 12px 0;
  }
}
