@use '@/styles/utils/mixins.scss' as *;

.motusStripWrapper {
  display: flex;
  align-items: center;
  background-color: $kiaSelectedItemColor;

  .motusStripLogo {
    width: 155px;
    height: 60px;
    position: relative;
    width: fit-content;
    margin-left: -21px;
  }

  .leftWhiteStip {
    position: relative;
    height: 60px;
    background: $kiaPolarWhite;
    left: 0;
    width: 12%;

    @include tab() {
      width: 0;
    }
  }
}

.motusText {
  margin-left: auto;
  font-size: 14px;
  color: $kiaPolarWhite;
  padding-right: 3rem;

  .desktopText {
    display: block;

    @include tab() {
      display: none;
    }

    @include mob() {
      display: none;
    }
  }

  .mobileText {
    display: none;

    @include tab() {
      display: block;
      text-align: center;
    }

    @include mob() {
      display: block;
      text-align: center;
    }
  }

  strong {
    font-weight: bold;
  }

  @include tab() {
    padding-right: 24px;
    width: 100%;
    text-align: center;
  }
}

@include tab() {
  .motusStripWrapper {
    .motusStripLogo {
      height: 80px;
    }

    .leftWhiteStip {
      height: 80px;
    }
  }
}

@include mob() {
  .motusStripWrapper {
    .motusStripLogo {
      height: 40px;
      margin-left: 0px;
    }

    .leftWhiteStip {
      height: 40px;
    }
  }
}

.copyRightText {
  font-size: 14px;
  color: $kiaPolarWhite;
  background-color: $kiaMidnightBlack;
  padding: 10px 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;

  @include tab() {
    padding: 10px 24px;
  }

  @include mob() {
    padding: 10px 16px;
    padding-bottom: 52px;
  }
}
