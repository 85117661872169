$kiaLiveRed: #ea0029;
$kiaMidnightBlack: #05141f;
$kiaPolarWhite: #ffffff;
$kiaPolarWhiteTransparent: rgba(255, 255, 255, 0.8);
$kiaAfternoonYellow: #f3c32c;
$kiaForestGreen: #5d7d2b;
$kiaCityGray: #b1b4b5;
$background: #f8f8f8;
$keyline: #cdd0d2;
$kiaCoolGrey: #9ba1a5;
$kiaBattleshipGrey: #697279;
$description: #37434c;
$border: #dddddd;
$inputBorderColor: $kiaMidnightBlack;
$navigationSeparator: #8a8b8a;
$navigationBottom: #ededed;
$selectedOption: #f1f1f1;
$sliderTrack: #9fa0a2;
$sliderBoxShadow: rgba(191, 192, 191, 0.16);
$overlayShadow: #00000042;
$kiaSilver: #bfc0bf;
$carStats: #636463;
$carTileShadow: 0 6px 10px 0 rgba(0, 0, 0, 0.16);
$darkBlackTransparent: #00000065;
$disabledButtonText: #9ba1a4;
$filterBlockBorder: #cfd0d3;
$detailsButton: #67727a;
$dealerBorderColor: #adadad;
$dealerNameColor: #231f20;
$dealerStreetColor: #606060;
$workTimeColor: #1a8119;
$kiaSelectedItemColor: #33444e;
$headerNavItemZindex: 2;
$extendMenuPopoverZindex: 1051;
$sliderArrowsZIndex: 1;
$imageBlackOverlay: linear-gradient(0deg, #00000026, #00000026);
$adminBackground: #efefef;
$adminBlack: #021520;
$kiaGreen: #547e19;
$kiaWarning: #f3c300;
$quickSearchOverlay: #05141f48;
$disabledButton: #eaebec;
$blogItemZIndex: 1;
$rollingStone: #717d84;
$kiaCharlestonColor: #1f2c35;
$kiaLabelGrey: #666666;
$disabledButtonText: #9ba1a4;
$adminTextBlack: #252631;
$adminRainCloud: #dfe4ee;
$kiaAdminGrey: #547e19;
$kiaCoolGreyTransparent: #9ba1a5e6;
$kiaSelectedItemColor: #33444e;
$kiaAdminBackgroundGreen: #f3fcee;
$kiaAdminBackgroundRed: #ffebec;
$kiaAdminBackgroundYellow: #fffaeb;
$boxShadowRadioInout: #9f9f9f;
$adminBorder: #f2f4f6;
$black: #000000;
$silver: #e0e0e0;
$grey: #989898;
$beige: #cab29c;
$blue: #3e72b0;
$red: #c4331c;
$brown: #77523c;
$cream: #fff8d9;
$gold: #bba372;
$green: #305043;
$orange: #d66530;
$yellow: #f9e214;
$bronze: #b45325;
$aquaHaze: #f8fafb;
$blackSqueeze: #f6f9fc;
$blackHaze: #ebecec;
$buttonGray: #cfd0d3;
$uploadTextColor: #9ba1a5;
$sunsetOrang: #ff4d4f;

$maxWideScreenDesktop: 1440px;
$minWideScreenDesktop: 1300px;
$minDesktop: 1025px;
$maxTablet: 1024px;
$minTablet: 768px;
$bigMobile: 480px;
$maxMobile: 479px;

/* value settings for @media queries */
$mob-galaxyS5: 360;
$mob-iphone6: 375;
$mob: 480;
$mob-tab: 768;
$tab: 992;
$tab-des: 1200;
$des: 1440;
$des-max: 1580;
$height-1: 900;
$height-2: 420;
