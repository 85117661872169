@use '@/styles/utils/vars.scss' as *;

@media screen and (min-width: $minTablet) {
  .bodyTypeWrapper {
    width: 826px;
  }
}

.bodyTypeWrapper {
  width: 100%;
}

.bodyList {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

@media screen and (min-width: $minTablet) {
  .bodyList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

.heading {
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 10px;
}

.bodyTypeItem {
  cursor: pointer;
  padding: 5px;

  @media screen and (min-width: $minTablet) {
    &:hover .bodyTypeText {
      text-shadow: 0.25px 0px 0.1px, -0.25px 0px 0.1px;
    }
  }
}

.bodyTypeText {
  text-align: center;
}

.bodyImage {
  margin: 5px auto;
}

.bodyTypeItemSelected {
  .bodyTypeText {
    text-shadow:
      0.25px 0px 0.1px,
      -0.25px 0px 0.1px;

    &::after {
      content: url('../../../../_icons/check.png');
      padding-left: 5px;
    }
  }

  .filterCount {
    color: $kiaMidnightBlack;
    font-weight: bold;
  }
}

.colourCircle {
  box-sizing: border-box;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 8px;
}

.withBorder {
  border: 1px solid $border;
}

.doorItem {
  border: 1px solid $border;
  padding: 15px;
  margin: 5px;

  @media screen and (min-width: $minTablet) {
    &:hover {
      color: $kiaPolarWhite;
      background-color: $kiaMidnightBlack;
    }
  }
}

.doorSelected {
  color: $kiaPolarWhite;
  background-color: $kiaMidnightBlack;
}

.bodyTypeDisabled {
  cursor: not-allowed;
  opacity: 0.6;

  .bodyTypeText {
    color: $grey;
    text-shadow: none;
  }

  .bodyImage {
    filter: grayscale(100%);
  }

  &:hover .bodyTypeText {
    text-shadow: none;
  }
}

.filterCount {
  font-size: 12px;
  line-height: 29px;
  color: $navigationSeparator;
  margin-left: 5px;
}
.filterCountDoor {
  font-size: 12px;
  line-height: 29px;
  color: $navigationSeparator;
  margin-left: 5px;
}
