@use '@/styles/utils/vars.scss' as *;

.filterTag {
  margin-right: 5px;
  margin-top: 7px;
}

@media screen and (max-width: $maxTablet) {
  .filterTag {
    margin-right: 4px;
    margin-top: 4px;
  }
}
