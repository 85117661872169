@use '@/styles/utils/mixins.scss' as *;

.resultsAction {
  display: flex;
  align-items: center;
  margin-left: 48px;
  @include mob() {
    align-items: flex-start;
  }

  .resultsActionLabel {
    margin-right: 4px;
    font-size: 14px;
  }
}

@media screen and (max-width: $maxTablet) {
  .resultsAction {
    margin-left: 0;
  }
}
