@use '@/styles/utils/mixins.scss' as *;

.radioButton {
  color: $kiaMidnightBlack;
  background-color: transparent;
  border: 1px solid $kiaSilver;
  font-family: 'KiaSignature';
  font-weight: 600;
  font-style: normal;
  text-align: center;
  font-size: 16px;
  line-height: 1.2;
  padding: 9px 20px;
  border-radius: 0;
  vertical-align: middle;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
  min-width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mob-galaxyS5() {
    font-size: 13px;
  }

  &:hover {
    color: $kiaMidnightBlack;
    border: 1px solid $kiaMidnightBlack;
  }
  &.active {
    color: $kiaPolarWhite;
    background-color: $kiaMidnightBlack;
    border: 1px solid $kiaMidnightBlack;
  }

  &Block {
    flex: 1 1 auto;
  }

  &Sm {
    padding: 9px 8px;
    @include mob-galaxyS5() {
      padding: 6px 5px;
    }
  }

  &Md {
    padding: 9px 20px;
  }

  &Lg {
    padding: 16px 24px;
  }

  &Xl {
    padding: 20px 30px;
  }
}
