@use '@/styles/utils/mixins.scss' as *;

.floatingCtaBtn {
  position: fixed;
  height: 50px;
  left: calc(100% - 50px);
  top: 50%;
  transform-origin: 0% 0%;
  transform: rotate(-90deg) translateX(-50%);
  width: max-content;
  z-index: 99;
  flex: 1;
  &.hideCta {
    display: none;
  }

  &:first-child {
    border-right: 1px solid $kiaMidnightBlack;
  }

  --rotation: -90deg;
  --transformFrom: 150%;
  --transformTo: 150%;
  --opacityTo: 1;
  --opacityFrom: 1;

  @include mob() {
    --rotation: 0deg;
    --transformFrom: 100%;
    --transformTo: 0%;
    --opacityTo: 1;
    --opacityFrom: 0;
  }

  @include mob() {
    position: fixed;
    top: auto;
    right: 0;
    left: 0;
    bottom: 0;
    transform: rotate(0deg);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    background-color: $kiaMidnightBlack;
  }

  &.hasSecondaryButton {
    @include mob() {
      height: auto;
      padding: 10px;
    }
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    width: 100%;

    @include mob() {
      justify-content: space-between;
      gap: 10px;
    }
  }

  .btn {
    background-color: $kiaMidnightBlack;
    border: 1px solid $kiaMidnightBlack;
    color: $kiaPolarWhite;
    font-size: 16px;
    line-height: 1.2;
    font-family: 'KiaSignature';
    font-weight: 600;
    font-style: normal;
    padding: 16px 20px;
    -webkit-transition: all 450ms ease-in-out;
    -moz-transition: all 450ms ease-in-out;
    -o-transition: all 450ms ease-in-out;
    -ms-transition: all 450ms ease-in-out;
    transition: all 450ms ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: max-content;
    border-radius: 0;
    height: auto;
    text-transform: capitalize;
    span {
      display: inline-block;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        background-color: $kiaMidnightBlack;
        transition: width 0.3s;
      }
    }

    &:hover,
    &:active,
    &.active {
      background-color: $kiaPolarWhite;
      color: $kiaMidnightBlack;
      span {
        &::before {
          width: 100%;
          transition: width 0.3s;
        }
      }
    }
    &.disabled,
    &:disabled {
      opacity: 0.4;
      pointer-events: none;
    }

    @include mob() {
      flex: 1;
      margin: 0;
      border: none;
      min-height: 50px;
    }
  }

  .primaryBtn {
    @include mob() {
      background-color: $kiaLiveRed;
      color: $kiaPolarWhite;

      &:hover,
      &:active,
      &.active {
        background-color: darken($kiaLiveRed, 10%);
      }
    }
  }

  .secondaryFloatingBtn {
    display: flex;
    background-color: $kiaLiveRed !important;
    color: $kiaPolarWhite;
    border: none;
    border-bottom: 1px solid transparent;
    padding: 16px 20px;
    align-items: center;
    justify-content: center;
    min-width: max-content;
    font-size: 16px;
    line-height: 1.2;
    font-family: 'KiaSignature';
    font-weight: 600;
    transition: all 450ms ease-in-out;

    &:hover,
    &:active,
    &.active {
      background-color: darken($kiaLiveRed, 10%) !important;
      color: $kiaPolarWhite;
      border: none;
    }

    @include mob() {
      display: flex;
      background-color: $kiaPolarWhite;
      color: $kiaMidnightBlack;
      border: 1px solid $kiaMidnightBlack;
      flex: 1;
      margin: 0;
      min-height: 50px;

      &:hover,
      &:active,
      &.active {
        background-color: $kiaMidnightBlack;
        color: $kiaPolarWhite;
      }
    }

    // When custom colors are provided, override the default styles
    &.customColors {
      &.desktop {
        @media (min-width: 768px) {
          background-color: var(--desktop-bg) !important;
          color: var(--desktop-text);

          &:hover {
            background-color: var(--desktop-hover-bg, var(--desktop-bg)) !important;
            color: var(--desktop-hover-text, var(--desktop-text));
          }
        }
      }

      &.mobile {
        @include mob() {
          background-color: var(--mobile-bg);
          color: var(--mobile-text);

          &:hover {
            background-color: var(--mobile-hover-bg, var(--mobile-bg));
            color: var(--mobile-hover-text, var(--mobile-text));
          }
        }
      }
    }
  }

  .btnWhite {
    background-color: $kiaPolarWhite;
    border-color: $kiaPolarWhite;
    color: $kiaMidnightBlack;
    span {
      &::before {
        background-color: $kiaMidnightBlack;
      }
    }
    &:hover,
    &:active,
    &.active,
    &:focus {
      color: $kiaMidnightBlack;
    }
  }

  .btnRed {
    background-color: $kiaLiveRed;
    border-color: $kiaLiveRed;
    color: $kiaPolarWhite;
    span {
      &::before {
        background-color: $kiaPolarWhite;
      }
    }
    &:hover,
    &:active,
    &.active,
    &:focus {
      background-color: $kiaLiveRed;
      border-color: $kiaLiveRed;
      color: $kiaPolarWhite;
    }
  }

  .btnOutline {
    background-color: transparent;
    border-color: $kiaMidnightBlack;
    color: $kiaMidnightBlack;
    span {
      &::before {
        background-color: $kiaMidnightBlack;
      }
    }
    &:hover,
    &:active,
    &.active,
    &:focus {
      color: $kiaMidnightBlack;
    }
  }

  .btnOutlineWhite {
    background-color: transparent;
    border-color: $kiaPolarWhite;
    color: $kiaPolarWhite;
    span {
      &::before {
        background-color: $kiaPolarWhite;
      }
    }
    &:hover,
    &:active,
    &.active,
    &:focus {
      color: $kiaPolarWhite;
    }
  }

  .btnGreyOutlineDark {
    background-color: $keyline;
    color: $kiaMidnightBlack;
    border: 1px solid $kiaMidnightBlack;
    border-radius: 15px;
    padding: 8px 12px 7px 12px;
    font-size: 12px;
    line-height: 13px;
    font-weight: 600;
    text-align: center;
    span {
      &::before {
        display: none;
      }
    }
    &:hover,
    &:active,
    &.active,
    &:focus {
      background-color: $kiaMidnightBlack;
      color: $kiaPolarWhite;
    }
  }

  .btnGreyOutlineDarkAlt {
    background-color: $keyline;
    color: $kiaMidnightBlack;
    border: 1px solid $keyline;
    border-radius: 15px;
    padding: 8px 12px 7px 12px;
    font-size: 12px;
    line-height: 13px;
    font-weight: 600;
    text-align: center;
    span {
      &::before {
        display: none;
      }
    }
    &:hover,
    &:active,
    &.active,
    &:focus {
      background-color: $kiaMidnightBlack;
      color: $kiaPolarWhite;
      border-color: $kiaPolarWhite;
    }
  }

  .btnTextLink {
    background-color: transparent;
    padding: 0;
    border: 0;
    color: $kiaMidnightBlack;
    font-size: 14px;
    line-height: 1;
    font-weight: 600;

    text-decoration: none;
    span {
      border-bottom: 1px solid $kiaMidnightBlack;
    }
    &:hover,
    &:active {
      color: $kiaMidnightBlack;
      text-decoration: none;
      span {
        border-bottom: 1px solid transparent;
      }
    }
    &.active {
      color: $kiaMidnightBlack;
      text-decoration: none;
      span {
        border-bottom: 1px solid transparent;
      }
    }
  }

  .btnTextLinkBorderless {
    padding: 13px 0;
    background-color: transparent;
    border: 0;
    color: $kiaMidnightBlack;
    font-size: 14px;
    line-height: 1;
    font-weight: 600;
    position: relative;
    display: block;

    &:hover {
      z-index: $headerNavItemZindex;
    }
    span {
      display: inline-block;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 11px;
        height: 1px;
        width: 0;
        background-color: $kiaMidnightBlack;
        transition: width 0.3s;
      }
    }

    &:hover,
    &:active,
    &.active {
      span {
        &::before {
          width: 100%;
          transition: width 0.3s;
        }
      }
    }
  }
}

@include mob() {
  .mobileHidden {
    display: none;
  }
}
