@use '@/styles/utils/mixins.scss' as *;

.dealershipInfoSectionName {
  height: 22px;
  font-size: 18px !important;
  font-weight: bold;
  color: $kiaMidnightBlack;
  margin-bottom: 20px;
  margin-top: 12px;

  @include tab() {
    text-transform: uppercase;
    padding: 0px 22px;
    margin: 0;
    height: 40px;
    align-items: center;
    display: flex;
    border-top: 1px solid $border;
  }
}

.dealershipInfoSectionNameOpened {
  @include tab() {
    border-bottom: 1px solid $border;
  }
}
