@use '@/styles/utils/vars.scss' as *;

.paymentOptionsWrapper {
  display: flex;
  margin-right: 10px;
}

.paymentOption {
  width: 50px;
  height: 30px;
  cursor: pointer;
  border: 1px solid $border;
  font-size: 12px;
  color: $kiaMidnightBlack;
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    margin-right: 5px;
  }
}

.paymentOptionSelected {
  color: $kiaPolarWhite;
  background-color: $kiaMidnightBlack;
  border: none;
}

@media screen and (max-width: $maxTablet) {
  .paymentOption {
    width: 30px;
    height: 26px;
    border-radius: 5px;
  }
}
