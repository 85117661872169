@use '@/styles/utils/mixins.scss' as *;

.priceWrapper {
  display: flex;

  .partWrapper {
    width: 100%;

    &:first-of-type {
      margin-right: 40px;
    }
  }

  .sectionWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
  }

  .priceFormatText {
    font-size: 12px;
    line-height: 18px;
    color: $kiaMidnightBlack;
  }
}

.rangesWrapper {
  display: flex;
}

.rangesCarFinderWrapper {
  margin-left: auto;
}

.sliderRange {
  :global {
    .ant-slider-mark {
      top: 20px !important;
    }
  }
}

@media screen and (max-width: $maxTablet) {
  .priceWrapper {
    display: block;

    .partWrapper {
      &:first-of-type {
        margin-right: 0;
      }
    }

    .sectionWrapper {
      display: block;
    }

    .rangesWrapper {
      margin-top: 10px;
      justify-content: space-between;
    }

    :global {
      .ant-slider {
        margin-left: 13px;
        margin-right: 13px;
      }
    }
  }

  .sliderRange {
    :global {
      .ant-slider-mark {
        top: 28px !important;
      }
    }
  }
}

.carFinderWrapper {
  @include tab() {
    margin-bottom: 50px;
  }
}
