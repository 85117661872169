@use '@/styles/utils/mixins.scss' as *;

@mixin buttonStyles {
  display: block;
  width: 130px;
  text-decoration: none;
  outline: none;

  &:hover {
    text-decoration: none;
    color: transparent;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-right: 22px;
}

.resultsCount {
  display: none;

  @include tab() {
    display: block;
    font-size: 12px;
    color: #000;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  @include tab() {
    flex-direction: column;
    margin-bottom: 0;
  }
}

.details {
  width: 410px;

  @include tab() {
    width: 100%;
  }

  .link {
    @include buttonStyles;
  }

  .bookButton {
    @include buttonStyles;
    width: 125px;
    margin-left: 10px;
    &:hover {
      cursor: pointer;
    }
  }

  .text {
    font-weight: bold;
    font-size: 12px;
    color: $kiaMidnightBlack;
    line-height: 15px;
    margin-left: 13px;
  }
}

.row {
  display: flex;
  margin-bottom: 16px;

  @include tab() {
    margin-bottom: 0;
    height: 40px;
    align-items: center;
  }
}

.hours {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100px;

  @include tab() {
    padding: 12px;
    height: 120px;
  }
}

.day {
  display: flex;
  font-size: 12px;
  line-height: 15px;
  color: $kiaMidnightBlack;
  text-transform: capitalize;
  margin-bottom: 9px;
  letter-spacing: -0.36px;
  margin-right: 50px;

  .dayOfWeek {
    width: 30px;
  }
}

.today {
  font-weight: bold;
  color: $kiaLiveRed;
}

.flex {
  display: flex;

  @include tab() {
    flex-direction: column;
    order: 1;
    width: 100%;
  }
}

.image {
  width: 250px;
  height: 128px;
  object-fit: cover;

  @include tab() {
    width: 100%;
    height: 300px;
    margin-top: 12px;
    margin-bottom: 12px;
    padding: 0px 22px;
  }

  @include mob() {
    height: 141px;
  }
}

.globalRow {
  @include tab() {
    display: none;
    padding: 0px 22px;
    margin-bottom: 12px;
  }
}

.arrow {
  display: none;
  @include tab() {
    display: block;

    &--open {
      transform: scaleY(-1);
    }
  }
  svg {
    width: 15px;
    height: 10px;
    margin-right: 11px;
  }
}

.showSection {
  @include tab() {
    display: block !important;
  }
}
