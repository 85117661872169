@use '@/styles/utils/vars.scss' as *;

.btn {
  background-color: $kiaMidnightBlack;
  border: 1px solid $kiaMidnightBlack;
  color: $kiaPolarWhite;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  padding: 20px 30px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
  display: inline-block;
  text-align: center;
  min-width: max-content;

  span {
    display: inline-flex;

    align-items: center;
    position: relative;
    justify-content: center;
    min-width: max-content;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      width: 0;
      background-color: $kiaPolarWhite;
      transition: width 0.3s;
    }
  }

  &:hover,
  &:active,
  &.active,
  &:focus {
    color: $kiaPolarWhite;
    span {
      &::before {
        width: 100%;
        transition: width 0.3s;
      }
    }
  }
  &.disabled,
  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
  &.btnSm {
    padding: 15px 30px;
  }
}

.btnWhite {
  background-color: $kiaPolarWhite;
  border-color: $kiaPolarWhite;
  color: $kiaMidnightBlack;
  span {
    &::before {
      background-color: $kiaMidnightBlack;
    }
  }
  &:hover,
  &:active,
  &.active,
  &:focus {
    color: $kiaMidnightBlack;
  }
}

.btnRed {
  background-color: $kiaLiveRed;
  border-color: $kiaLiveRed;
  color: $kiaPolarWhite;
  span {
    &::before {
      background-color: $kiaPolarWhite;
    }
  }
  &:hover,
  &:active,
  &.active,
  &:focus {
    color: $kiaPolarWhite;
  }
}

.btnOutline {
  background-color: transparent;
  border-color: $kiaMidnightBlack;
  color: $kiaMidnightBlack;
  span {
    &::before {
      background-color: $kiaMidnightBlack;
    }
  }
  &:hover,
  &:active,
  &.active,
  &:focus {
    color: $kiaMidnightBlack;
  }
}

.btnOutlineWhite {
  background-color: transparent;
  border-color: $kiaPolarWhite;
  color: $kiaPolarWhite;
  span {
    &::before {
      background-color: $kiaPolarWhite;
    }
  }
  &:hover,
  &:active,
  &.active,
  &:focus {
    color: $kiaPolarWhite;
  }
}

.btnGreyOutlineDark {
  background-color: $keyline;
  color: $kiaMidnightBlack;
  border: 1px solid $kiaMidnightBlack;
  border-radius: 15px;
  padding: 8px 12px 7px 12px;
  font-size: 12px;
  line-height: 13px;
  font-weight: 600;
  text-align: center;
  span {
    &::before {
      display: none;
    }
  }
  &:hover,
  &:active,
  &.active,
  &:focus {
    background-color: $kiaMidnightBlack;
    color: $kiaPolarWhite;
  }
}

.btnGreyOutlineDarkAlt {
  background-color: $keyline;
  color: $kiaMidnightBlack;
  border: 1px solid $keyline;
  border-radius: 15px;
  padding: 8px 12px 7px 12px;
  font-size: 12px;
  line-height: 13px;
  font-weight: 600;
  text-align: center;
  span {
    &::before {
      display: none;
    }
  }
  &:hover,
  &:active,
  &.active,
  &:focus {
    background-color: $kiaMidnightBlack;
    color: $kiaPolarWhite;
    border-color: $kiaPolarWhite;
  }
}

.btnTextLink {
  background-color: transparent;
  padding: 0;
  border: 0;
  color: $kiaMidnightBlack;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 600;
  span {
    &::before {
      width: 100%;
      background-color: $kiaMidnightBlack;
      transition: width 0.3s;
    }
  }
  &:hover,
  &:active,
  &.active,
  &:focus {
    color: $kiaMidnightBlack;
    span {
      &::before {
        width: 0;
        transition: width 0.3s;
      }
    }
  }
}

.btnTextLinkBorderless {
  padding: 13px 0;
  background-color: transparent;
  border: 0;
  color: $kiaMidnightBlack;
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  position: relative;
  display: block;

  &:hover {
    z-index: $headerNavItemZindex;
  }
  span {
    display: inline-block;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 11px;
      height: 1px;
      width: 0;
      background-color: $kiaMidnightBlack;
      transition: width 0.3s;
    }
  }

  &:hover,
  &:active,
  &.active {
    span {
      &::before {
        width: 100%;
        transition: width 0.3s;
      }
    }
  }
}
