@use '@/styles/utils/vars.scss' as *;

.inputWrapper {
  width: 126px;
  height: 30px;
  position: relative;

  .inputPrefix {
    font-size: 12px;
    line-height: 14px;
    color: $kiaMidnightBlack;
    position: absolute;
    left: 10px;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%);
  }
}

@media screen and (max-width: $maxTablet) {
  .inputWrapper {
    :global {
      .priceInput {
        border-radius: 5px;

        .ant-input-number-input {
          border-radius: 5px;
          //height: 26px;
        }
      }
    }
  }
}
