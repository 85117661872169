@use '@/styles/utils/mixins.scss' as *;

.financeOptionWrapper {
  .headingElement {
    position: relative;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;

      &.titleXl {
        font-size: 60px;
        line-height: 1;

        @include tab() {
          font-size: 48px;
          line-height: 1;
        }
      }

      &.titleLg {
        font-size: 48px;
        line-height: 1;

        @include tab() {
          font-size: 36px;
          line-height: 1;
        }
      }

      &.titleMd {
        font-size: 24px;
        line-height: 1;
      }

      &.titleXs {
        font-size: 16px;
        line-height: 1;
      }
    }

    .titleText {
      font-size: 16px;
      line-height: 1;
      font-weight: 600;
    }
  }
  .formLabel {
    font-size: 12px;
    line-height: 1.2;
    color: $kiaMidnightBlack;
    margin-bottom: 18px;
  }
  .formInput {
    border-color: $kiaSilver !important;
    height: auto !important;
  }

  .formInput:focus-visible,
  .formInput[data-focus-visible] {
    border-color: $kiaMidnightBlack !important;
    box-shadow: 0px 1px 0px 0px $kiaMidnightBlack !important;
  }
  .prefix {
    background: none;
    background: none;
    border: none;
    border-bottom: 1px solid;
    border-radius: unset;
    border-color: $kiaSilver !important;
    padding-left: 0px;
  }
  .priceBreakdownBox {
    background: $background;
    padding: 24px;
    @media screen and (max-width: 991.98px) {
      margin: 0px -24px;
      padding: 24px 24px;
    }
  }

  .btnTextLink {
    display: flex;
    align-items: center;
    gap: 8px;
    text-decoration: none;
    border-bottom: 0;
    .linkText {
      border-bottom: 1px solid $kiaMidnightBlack;
      -webkit-transition: all 450ms ease-in-out;
      -moz-transition: all 450ms ease-in-out;
      -o-transition: all 450ms ease-in-out;
      -ms-transition: all 450ms ease-in-out;
      transition: all 450ms ease-in-out;
      &:hover,
      &:active {
        border-bottom: 1px solid transparent;
        color: $kiaMidnightBlack;
      }
      &.active {
        border-bottom: 1px solid transparent;
        color: $kiaMidnightBlack;
      }
    }

    &:hover,
    &:focus,
    &:active,
    &:focus-visible {
      text-decoration: none;
    }
  }
  .ctaBtn {
    padding: 13px 24px;
    text-transform: capitalize;
  }
  .blockContent {
    font-size: 14px;
    line-height: 1.4;

    p:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
