@use '@/styles/utils/vars.scss' as *;

.pageTitle {
  height: 60px;
  font-size: 60px !important;
  font-weight: 600;
  letter-spacing: -1.6px;
  color: $kiaMidnightBlack;
  text-transform: capitalize;

  @media screen and (max-width: $maxTablet) {
    width: 100%;
    height: auto;
    font-size: 36px !important;
    line-height: 1.2;
    letter-spacing: normal;
    margin: 0px;
    margin-bottom: 0px;
  }
}
