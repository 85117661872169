@use '@/styles/utils/mixins.scss' as *;
.tradeInSection {
  .headingElement {
    position: relative;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleLg {
        font-size: 48px;
        line-height: 60px;
        @include tab() {
          font-size: 36px;
          line-height: 40.2px;
        }
      }
      &.titleMd {
        font-size: 36px;
        line-height: 40.2px;
      }
      &.titleXs {
        font-size: 16px;
        line-height: 19.2px;
      }
    }
    .titleText {
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 28px;
    }
  }
}
