@import './vars';
/* all media settings */
/*
  @mixin response
  @param $prefix {'max' or 'min'}
  @param $media {number}
 */
@mixin response($prefix, $media) {
  @media only screen and ($prefix+-width: $media+px) {
    @content;
  }
}
/*  using '@mixin response' to create own @mixins for max-width of displays */
@mixin mob-galaxyS5() {
  @include response(max, $mob-galaxyS5) {
    @content;
  }
}
@mixin mob-iphone6() {
  @include response(max, $mob-iphone6) {
    @content;
  }
}
@mixin mob() {
  @include response(max, $mob) {
    @content;
  }
}
@mixin mob-tab() {
  @include response(max, $mob-tab) {
    @content;
  }
}
@mixin tab() {
  @include response(max, $tab) {
    @content;
  }
}
@mixin tab-des() {
  @include response(max, $tab-des) {
    @content;
  }
}
@mixin des() {
  @include response(max, $des) {
    @content;
  }
}

@mixin des-max() {
  @include response(max, $des-max) {
    @content;
  }
}

/*  using '@mixin response' to create own @mixins for min-width of displays */
@mixin mob-galaxyS5-min() {
  @include response(min, $mob-galaxyS5 + 1) {
    @content;
  }
}
@mixin mob-iphone6-min() {
  @include response(min, $mob-iphone6 + 1) {
    @content;
  }
}
@mixin mob-min() {
  @include response(min, $mob + 1) {
    @content;
  }
}
@mixin mob-tab-min() {
  @include response(min, $mob-tab + 1) {
    @content;
  }
}
@mixin tab-min() {
  @include response(min, $tab + 1) {
    @content;
  }
}
@mixin tab-des-min() {
  @include response(min, $tab-des + 1) {
    @content;
  }
}
@mixin des-min() {
  @include response(min, $des + 1) {
    @content;
  }
}

@mixin des-max-min() {
  @include response(min, $des-max + 1) {
    @content;
  }
}
