@use '@/styles/utils/vars.scss' as *;

.sectionName {
  height: 15px;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: $kiaMidnightBlack;
  margin-right: 23px;
}

@media screen and (max-width: $maxTablet) {
  .sectionName {
    margin-right: 10px;
  }
}
