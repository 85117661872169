@use '@/styles/utils/vars.scss' as *;

.selectInput {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }

  .selectTriangle {
    margin-left: 12px;
  }
  .selectTriangleActive {
    transform: scaleY(-1);

    path {
      fill: $kiaMidnightBlack;
    }
  }
}

@media screen and (max-width: $maxTablet) {
  .selectInput {
    .selectTriangle {
      margin-left: 10px;
    }
  }
}
