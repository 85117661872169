@use '@/styles/utils/vars.scss' as *;

.sortOption {
  width: 160px;
  height: 30px;
  padding-left: 11px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: $kiaMidnightBlack;
  text-transform: capitalize;

  &:hover {
    cursor: pointer;
    background-color: $selectedOption;
  }
}

.sortOptionSelected {
  font-weight: bold;
}
