@use '@/styles/utils/vars.scss' as *;

.tagWrapper {
  box-sizing: border-box;
  background-color: $kiaMidnightBlack;
  border-radius: 100px;
  height: 25px;
  padding: 3px 3px 3px 12px;
  display: flex;
  align-items: center;

  .tagName {
    font-size: 12px;
    line-height: 13px;
    font-weight: bold;
    color: $kiaPolarWhite;
  }

  svg {
    margin-left: 10px;
    cursor: pointer;
    width: 19px;
    height: 19px;
  }
}

.tagWrapper.tagWrapperLight {
  background-color: $detailsButton;
}
