@use '@/styles/utils/vars.scss' as *;

.inputsSeparator {
  width: 20px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '-';
    font-size: 12px;
    line-height: 18px;
    color: $kiaMidnightBlack;
  }
}
